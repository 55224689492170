import React from 'react';
import {AboInstance, GuestAbo, InstanceData} from "../Helper/DataTypes";
import {openAboBooking} from "./VXPayHelper";
import {getGuest} from "./SessionHelper";
import {getPrice} from './Helper';
import {mutateCancelSubscription} from "./GraphQLHelper";

type HeaderType = { logo: string, isMySubs: Boolean, t: any };

function Header(props: HeaderType) {
	return (
		<div className={"header content__wrapper--premium"}>
			{props.logo && <img className={"header__image"} src={props.logo} alt="" />}
			{props.isMySubs &&
			<div className={"header__text"}>
				{props.t('MyAbos')}
			</div>
			}

			{!props.isMySubs &&
			<div className={"header__text header__text--big"}>
				Telegram VIP
			</div>
			}
		</div>
	);
}

function formatDate(date: Date) {
	return date.toLocaleDateString('de-DE', {
		day: '2-digit',
		month: '2-digit',
		year: '2-digit'
	});
}

function getPossibleEndDate(nextRefresh: Date, benefitInterval: number) {
	const now = new Date();
	now.setHours(0,0,0,0);
	const lastPossibleUnsubscribeDate = new Date(nextRefresh)
	lastPossibleUnsubscribeDate.setDate(nextRefresh.getDate() - 1);
	lastPossibleUnsubscribeDate.setHours(0,0,0,0);

	if (now > lastPossibleUnsubscribeDate) {
		return formatDate(new Date(lastPossibleUnsubscribeDate.setDate(nextRefresh.getDate() + benefitInterval)));
	} else {
		return formatDate(nextRefresh);
	}
}


function getAboTile(abo: GuestAbo, queryAbos: Function, data: any, setAbos: Function, history: any, t: any, modalAboId: number, setModalAboId: any, updatePremiumStatus?: Function, setShowUnsubscribeConfitmationModal?: Function, showUnsubscribeConfitmationModal?: boolean) {
	const currencySign = (data.usdMode ? '$' : '€');
	const price = getPrice(abo.price, currencySign) + ' ' + currencySign;
	const currentInstance = abo.instances.length > 0 ? abo.instances[abo.instances.length - 1] : null;

	let banner;
	let description;
	let footer;
	let unsubscribeModal;
	
	if (currentInstance && currentInstance.status !== 'STOPPED') {
		if (currentInstance.status === 'RUNNING') {
			const premiumStatus = data.premiumStatus;

			if (premiumStatus === 'avs_required') {
				description = <div className={"abo-tile__text"}>{abo.description}</div>;
				footer = <div className="abo-tile__button-container">
					<span onClick={() => window.location.href = data.avsCheckoutUrl} className={"abo-tile__button"} key="0">{t('DoAvs')}</span>
					<div className={"abo-tile__ready_id"}>{t('ReadyID')}</div>
				</div>;
			} else if (premiumStatus === 'avs_pending') {
				description = <div className={"abo-tile__text"}>{abo.description}</div>;
				footer = <div className="abo-tile__button-container">
					<span className={"abo-tile__button abo-tile__button--disabled"} key="0">{t('PendingAvs')}</span>
				</div>;
			} else if (currentInstance.end) {
				let translated = t('EndsAt');
				const endDate = formatDate(currentInstance.end);

				banner = (
					<div className={"abo-tile__banner"}><i className={"icon icon--xs icon--mt-0 icon--close"}></i> {t('UnsubscribedTo')} {endDate}
					</div>);
				description = (
					<div className={"abo-tile__text--abo-info"}>
						<div>{t('From')} {abo.actorName}</div>
						<br />
						<div> {price}* {t('For')} {abo.paymentInterval} {t('Days')} </div>
					</div>
				);

				footer = <div className="abo-tile__button-container">
					<div className={"abo-tile__button abo-tile__button--disabled"} key="0">{t('AboUnsubscribed')}</div>
					<div className="abo-tile__button-info"
					     key="1">{translated.charAt(0).toLocaleUpperCase() + translated.substr(1)}&nbsp;{endDate}</div>
				</div>;
			} else {
				let nextRefresh = '';
				let endDate = '';
				if (currentInstance.nextRefresh) {
					nextRefresh = formatDate(currentInstance.nextRefresh);
					endDate = getPossibleEndDate(currentInstance.nextRefresh, abo.benefitInterval);
				}

				unsubscribeModal = (modalAboId === abo.id) ? (showUnsubscribeConfitmationModal ? getUnsubscribeConfirmationModal(data, setAbos, abo, t, endDate, setShowUnsubscribeConfitmationModal, queryAbos, setModalAboId) : getUnsubscribeModal(abo.id, setModalAboId, abo, t, endDate, setShowUnsubscribeConfitmationModal)) : '';
				banner = (
					<div className={"abo-tile__banner"}><i className={"icon icon--xs icon--mt-0 icon--check"}></i> {t('ActivExtended')} {nextRefresh}
					</div>);
				description = (
					<div className={"abo-tile__text--abo-info"}>
						<div>{t('From')} {abo.actorName}</div>
						<br />
						<div> {price}* {t('For')} {abo.paymentInterval} {t('Days')} </div>
					</div>
				);
				footer = <div>
					<div onClick={() => setModalAboId(abo.id)} className={"abo-tile__button"} key="0">{t('AboUnsubscribe')}</div>
					<div className="abo-tile__button-info" key="1">{price}* - {t('Every') + ' ' + abo.paymentInterval + ' ' + t('Days')}</div>
				</div>;
			}

		} else {
			banner = null;
			description = <div className={"abo-tile__text"}>{abo.getDescription()}</div>;
			footer = null;
		}

	} else if (!abo.isOnlyPreview) {
		description = <div className={"abo-tile__text"}>{abo.getDescription()}</div>;
		footer = <div className="abo-tile__button-container">
			<span onClick={() => bookAbo(abo.id, queryAbos, data, setAbos, history, updatePremiumStatus)}
			      className={"abo-tile__button"}
			      key="0">{t('Subscribe')}</span>
			<div className={"abo-tile__button-info"} key="1"> {price}* / {abo.paymentInterval} {t('Days')}, {t('Terminable')}</div>
			{data.avs && <div className={"abo-tile__ready_id"}>{t('ReadyID')}</div>}
		</div>;
	} else {
		description = <div className={"abo-tile__text"}>{abo.getDescription()}</div>;
		footer = <div className="abo-tile__button-container">
			<span className={"abo-tile__button abo-tile__button--disabled"} key="0">{t('Subscribe')}</span>
			<div className={"abo-tile__button-info"} key="1">{t('AlreadySub')}</div>
		</div>;
	}
	const style = abo.picture ? {backgroundImage: `url(${abo.picture})`} : {};
	return (
		<div className={"abo-tile"} key={abo.id}>
			<div className={"abo-tile__image-wrapper"}>
				{banner}
				<div className={"abo-tile__image"} style={style}></div>
			</div>
			<div className={"abo-tile__info-wrapper"}>
				<div className={"abo-tile__text-wrapper"}>
					<div className={"abo-tile__headline"}>{abo.getName()}</div>
					{description}
				</div>
				{footer}
				{unsubscribeModal}
			</div>
		</div>
	)
}

function unsubscribeAbo(id: number, setShowUnsubscribeConfitmationModal?: Function) {
	const guest = getGuest();

	if (guest && guest.vxoneSessionId) {
		mutateCancelSubscription(guest, id).then((res: any) => {
			if (res && res.data && res.data.guest && res.data.guest.cancelSubscription && res.data.guest.cancelSubscription.success) {
				typeof setShowUnsubscribeConfitmationModal === 'function' && setShowUnsubscribeConfitmationModal(true);
			}
		});
	}
}

function getUnsubscribeModal(id: number, setModalAboId: any, abo: GuestAbo, t: any, endDate: string, setShowUnsubscribeConfitmationModal?: Function) {
	return (
		<div className={"unsubscribe-modal__wrapper"}>
			<div className={"unsubscribe-modal"}>
				<div className={"unsubscribe-modal__close-btn"} onClick={() => setModalAboId(0)}>
					<i className="icon icon--sm icon--mt-0 icon--close pointer"></i>
				</div>
				<div className={"unsubscribe-modal__abo-container"}>
					<div className={"unsubscribe-modal__abo-container__img"} style={{backgroundImage: `url(${abo.picture})`}}>
						{!abo.picture && <img className="footer__logo" src="../images/telegram_logo.svg" alt="telegram" />}
					</div>
					<div className={"unsubscribe-modal__abo-container__text"}>
						<div className={"unsubscribe-modal__abo-container__text-headline"}>{abo.getName()}</div>
						<div className={"unsubscribe-modal__abo-container__text-subline"}>
							<div>{t('From')} {abo.actorName}</div>
							<br />
							<div>{getPrice(abo.price, '€')} €* {t('For')} {abo.paymentInterval} {t('Days')}</div>
						</div>
					</div>
				</div>

				<div className={"unsubscribe-modal__text-container"}>
					<div className={"unsubscribe-modal__text-container__headline"}>{t("UnsubscribeTextHeadline")}</div>
					<div className={"unsubscribe-modal__text-container__subline"}>{t("UnsubscribeTextSubline", {endDate},)}</div>
					<div onClick={() => unsubscribeAbo(id, setShowUnsubscribeConfitmationModal)}
					     className={"abo-tile__button"}>{t('AboUnsubscribe')}</div>
					<div onClick={() => setModalAboId(0)} className={"unsubscribe-modal__text-container__cancel"}>{t('Cancel')}</div>
				</div>
			</div>
		</div>
	);
}

function getUnsubscribeConfirmationModal(data: any, setAbos: any, abo: GuestAbo, t: any, endDate: string, setShowUnsubscribeConfitmationModal?: Function, queryAbos?: Function, setModalAboId?: Function) {
	const guest       = getGuest();
	const now         = new Date();
	const currentDate = now.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: 'numeric' });
	const currentTime = now.toLocaleTimeString('de-DE', {hour: '2-digit', minute:'2-digit'})

	const close = () => {
		if (typeof queryAbos === 'function') {
			queryAbos(data.actorId, setAbos);
		}
		typeof setModalAboId === 'function' && setModalAboId(0); 
		typeof setShowUnsubscribeConfitmationModal === 'function' && setShowUnsubscribeConfitmationModal(false);
	}

	return <div className={"unsubscribe-modal__wrapper"}>
			<div className='unsubscribe-modal'>
				<div className={"unsubscribe-modal__close-btn"} onClick={close}>
					<i className="icon icon--sm icon--mt-0 icon--close pointer"></i>
				</div>
				<div className={"unsubscribe-modal__abo-container"}>
					<div className={"unsubscribe-modal__abo-container__img"} style={{backgroundImage: `url(${abo.picture})`}}>
						{!abo.picture && <img className="footer__logo" src="../images/telegram_logo.svg" alt="telegram" />}
					</div>
					<div className={"unsubscribe-modal__abo-container__text"}>
						<div className={"unsubscribe-modal__abo-container__text-headline"}>{abo.getName()}</div>
						<div className={"unsubscribe-modal__abo-container__text-subline"}>
							<div>{t('From')} {abo.actorName}</div>
							<br />
							<div>{getPrice(abo.price, '€')} €* {t('For')} {abo.paymentInterval} {t('Days')}</div>
						</div>
					</div>
				</div>
				<div className={"unsubscribe-modal__text-container unsubscribe-modal__text-container--confirmation"}>
					<div>
						<div className='unsubscribe-modal__text-container__headline'>{t('AboUnsubscribeConfirmationHeadline', {dateTime: t('AboUnsubscribeConfirmationDateTime', {date: currentDate, time: currentTime})})}</div>
						<div className='unsubscribe-modal__text-container__subline unsubscribe-modal__text-container__subline--confirmation'>{t('AboUnsubscribeConfirmationSubline1', {name: abo.name, endDate: endDate})}</div>
						<div className='unsubscribe-modal__text-container__subline unsubscribe-modal__text-container__subline--confirmation'>{t('AboUnsubscribeConfirmationSubline2')}</div>
						<div className='unsubscribe-modal__text-container__subline unsubscribe-modal__text-container__subline--confirmation'>{t('AboUnsubscribeConfirmationSubline3')}</div>
						<div className='unsubscribe-modal__text-container__footer'>
							<div className='unsubscribe-modal__text-container__footer-headline'>{t('AboUnsubscribeConfirmationFooterHeadline')}</div>
							<div className='unsubscribe-modal__text-container__footer-subline'>{t('AboUnsubscribeConfirmationFooterSubline1', {name: guest?.screenname})}</div>
							<div className='unsubscribe-modal__text-container__footer-subline'>{t('AboUnsubscribeConfirmationFooterSubline2', {dateTime: t('AboUnsubscribeConfirmationDateTime', {date: currentDate, time: currentTime})})}</div>
							<div className='unsubscribe-modal__text-container__footer-subline'>{t('AboUnsubscribeConfirmationFooterSubline3')}</div>
							<div className='unsubscribe-modal__text-container__footer-subline'>{t('AboUnsubscribeConfirmationFooterSubline4', {abo: abo.name})}</div>
							<div className='unsubscribe-modal__text-container__footer-subline'>{t('AboUnsubscribeConfirmationFooterSubline5', {dateTime: endDate})}</div>
							<div className='unsubscribe-modal__text-container__footer-subline'>{t('AboUnsubscribeConfirmationFooterSubline6', {aboAmount: abo.price})}</div>
						</div>
					</div>
					<a  className='abo-tile__button' href={`https://www.visit-x.net/VXREST/v1/vxpay/abobooking/unsubConfirmationPdf?access_token=${data.accessToken}&locked_access_token=null&aboName=${abo.name}&currentDate=${currentDate}&currentTime=${currentTime}&expireDate=${endDate}&aboAmount=${abo.price}`}>
						{t('AboUnsubscribeConfirmationButton')}
					</a>
					<div className="unsubscribe-modal__text-container__cancel" onClick={close}>{t('AboUnsubscribeConfirmationClose')}</div>
				</div>
			</div>
		</div>;
}

function bookAbo(id: number, queryAbos: Function, data: any, setAbos: Function, history: any, updatePremiumStatus?: Function) {
	const guest = getGuest();

	if (guest && guest.vxoneSessionId) {
		openAboBooking(id);

		const messageCallback = (m: MessageEvent) => {
			if (m.origin === 'https://www.visit-x.net') {
				const eventData = JSON.parse(m.data);

				if (eventData.type === 'modalbox-success' || eventData.type === 'modalbox-iframe-close') {
					queryAbos(data.actorId, setAbos);

					if (updatePremiumStatus) {
						updatePremiumStatus();
					}
					window.removeEventListener('message', messageCallback);
				}
			}
		};

		window.addEventListener('message', messageCallback);

	} else {
		history.push("/login/");
	}
}

function createGuestAbos(abos: any, setter: Function) {
	const guestAbos = [];
	for (let i = 0; i < abos.length; i++) {
		const tmp = abos[i].abo;
		const guestAbo = new GuestAbo();

		Object.assign(guestAbo, tmp);
		guestAbo.createdAt = new Date(tmp.createdAt);

		if (!guestAbo) {
			continue;
		}

		let valid = false;
		for (let j = 0; j < guestAbo.benefits.length; j++) {
			const tmp = guestAbo.benefits[j];
			if (tmp.type && tmp.type.name === 'Telegram' && tmp.subType && tmp.subType.name === 'CUSTOM') {
				valid = true;
			}
		}

		if (!valid) {
			continue;
		}

		const instances = [];
		for (let n = 0; n < abos[i].instances.length; n++) {

			if (n === 0) {
				guestAbo.instanceData = new InstanceData();

				if (abos[i].instances[n].data) {
					Object.assign(guestAbo.instanceData, abos[i].instances[n].data);
				}
			}

			const instance = new AboInstance();

			Object.assign(instance, abos[i].instances[n]);

			instance.end = abos[i].instances[n].end ? new Date(abos[i].instances[n].end) : undefined;
			instance.nextRefresh = abos[i].instances[n].nextRefresh ? new Date(abos[i].instances[n].nextRefresh) : undefined;
			instance.lastRefresh = abos[i].instances[n].lastRefresh ? new Date(abos[i].instances[n].lastRefresh) : undefined;
			instance.start = abos[i].instances[n].start ? new Date(abos[i].instances[n].start) : undefined;

			instances.push(instance);
		}
		guestAbo.instances = instances;

		guestAbos.push(guestAbo);
	}
	setter(guestAbos);
}


export {Header, getAboTile, createGuestAbos}