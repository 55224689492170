import {isGerman} from "./Helper";

export type GuestDataType = {
	id: number,
	isPayingCustomer: boolean,
	name: string,
	screenname: string;
	email: string;
	fsk18: boolean;
	isRestricted: boolean;
	uhash: string;
	vxoneSessionId: string;
	hasAvs: boolean;
	premiumStatus: string;
}
export type EmailVerifyDataType = { safeparamhash: string, u: number }
export type BuyContentType = { id: string, title: string, description: string, price: number, status: string, contentId: any, guestId: string, messageId: string, uhash: string, fileType: string, text?: any, url?: string, canceledRevoked?: boolean, currency: string }

export type CaptchaDataType = {
	use: boolean,
	useOnFail: boolean,
	useOnSignup: boolean,
	key: string|null,
	url: string,
};

export class GuestAbo {
	id: number = 0;
	name: string = '';
	description: string = '';
	nameTranslation?: AboTranslation | null = null;
	descriptionTranslation?: AboTranslation | null = null;
	picture?: string;
	price: number = 0;
	priceDiscounted?: number;
	benefitInterval: number = 0;
	paymentInterval: number = 0;
	renew: boolean = true;
	active: boolean = true;
	trialIntervalCount?: number;
	trialPrice?: number;
	actorId?: number;
	actorName?: string;
	pfmId: number = 0;
	creatorId: number = 0;
	createdAt: Date = new Date();
	instanceData?: InstanceData;
	benefits: Array<AboBenefit> = [];
	instances: Array<AboInstance> = [];
	isOnlyPreview: boolean = false;

	getName() {
		if (!this.nameTranslation) {
			return this.name;
		}

		if (isGerman()) {
			return this.nameTranslation.de;
		}

		return this.nameTranslation.en;
	}

	getDescription() {
		if (!this.descriptionTranslation) {
			return this.description;
		}

		if (isGerman()) {
			return this.descriptionTranslation.de;
		}

		return this.descriptionTranslation.en;
	}
}

class AboTranslation {
	de: string = '';
	en: string = '';
}

export class InstanceData {
	currency?: string;
	price?: number;
	foreignCurrency?: string;
	foreignPrice?: number;
}

export class AboBenefit {
	id?: number;
	type?: BenefitType;
	subType?: BenefitType;
	singlePayout?: boolean;
	json?: string;
}

export class BenefitType {
	name?: string;
	readableIdentifier?: string;
}

export class AboInstance {
	id?: number;
	status?: string;
	end?: Date;
	aboId?: number;
	guestId?: number;
	communipayContractId?: number;
	nextRefresh?: Date;
	start?: Date;
	lastRefresh?: Date;
	totalRefreshes?: number;
}

export interface IResponseDataPayment {
	checkoutUrl?: string;
	success?: boolean;
}

export interface IResponse {
	success: boolean;
	data: IResponseDataPayment;
}
