import './Login.scss';
import {useTranslation}      from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {useHistory}          from "react-router-dom";
import { message, 
         validateInput, 
         handleFocusHelper,} from '../Helper/Helper';

import { restCall, checkEmail, 
         checkScreenname}    from '../Helper/Api';
import {GuestDataType}       from '../Helper/DataTypes';
import FooterMeta            from '../Footer/FooterMeta';
import NavigationBar         from '../Components/NavigationBar';
import PoweredBy             from './PoweredBy';
import {setAccessToken}      from "../Helper/VXPayHelper";
import ReCAPTCHA             from "react-google-recaptcha";
import RouterHelper from '../Helper/RouterHelper';
import { Link }          from "react-router-dom";
import { isGerman } from '../Helper/Helper';

declare global {
    interface Window {
        ga:any;
    }
}

function SignUp(props: any) {
    const {t}     = useTranslation();
    const history = useHistory();

    const [text, setText] = useState({
        email:      '',
        screenname: '',
        password:   '',
    })

    const [showLabel, setShowLabel] = useState({
        email:      false,
        screenname: false,
        password:   false,
    });

    const [recaptchaResponse, setRecaptchaResponse] = useState('');
   
    const [showPassword, setShowPassword] = useState(false);

    const [showError, setShowError] = useState({
        email:    {
            show: false,
            text: t('FillOut')
        },
        screenname:    {
            show: false,
            text: t('FillOut')
        },
        password: {
            show: false, 
            text: t('FillOut'),
        },
    })

    const [hasSubmit, setHasSubmit] = useState(false);

    function onCaptchaCompleted(token: any) {
        setRecaptchaResponse(token);
    }

    // Get Access Token and Transfer Token
    useEffect(() => {
        if (!props.accessToken) {
            props.prepareAnonymousTokens(props.accessToken);
        }
    },[props]);

    const [showMessage, setShowMessage] = useState({
        isError: false,
        show:    false,
        text:    '',
    })

    function handleFocus(evt: any) {
        handleFocusHelper(evt, showLabel, setShowLabel);
    }

    function handleChange(evt: any) {
        setText({ ...text, [evt.target.name]: evt.target.value });
    }

    function toLogin() {
        history.push("/login/");
    }

    function togglePassword() {
        setShowPassword(!showPassword);
    }

    function submit(e: any) {
        e.preventDefault();
        if (validateInput(text, showError, setShowError, t('FillOut'), t('EmailWrong'))) { return; }
        const storageContentId: string = sessionStorage.getItem('contentId') || '';

        if (props.accessToken) {
            setHasSubmit(true);
            checkEmail(encodeURIComponent(text.email), props.accessToken, (emailResp: any) => {
                if (!emailResp.success) {
                    showError.email.show = true;
                    showError.email.text = t(emailResp.error.message);
                    setHasSubmit(false);
                    setShowError({...showError});
                    return;
                }

                checkScreenname(encodeURIComponent(text.screenname), props.accessToken, (screenResp: any) => {
                    if (!screenResp.success) {
                        showError.screenname.show = true;
                        showError.screenname.text = t(screenResp.error.message);
                        setHasSubmit(false);
                        setShowError({...showError});
                        return;
                    }
                    restCall('signup/create', props.accessToken, {paidContentId: storageContentId, ...text, 'g-recaptcha-response': recaptchaResponse, agbAccepted: 1}, (data: any) => {
                        if (data.success) {
                            message(t('SuccessfulSignedUp'), false, setShowMessage);
                            props.setGuest(data.data, (newGuest: GuestDataType) => {
                                const storageContentId: string = sessionStorage.getItem('contentId') || '';
                                if ("ga" in window) {
                                    const tracker = window.ga.getAll()[0];
                                    if (tracker)
                                        tracker.send("event", "telegram", "signup");
                                }

                                setAccessToken(props.accessToken);
                                if (storageContentId) {
                                    history.push(`/buy/${storageContentId}`);
                                } else {
                                    history.push(`/vip/`);
                                }
                            });
                        } else {
                            message(t(data.error.message), true, setShowMessage);
                            setHasSubmit(false);
                            setShowError({
                                email:    {
                                    show: true,
                                    text: t('BadCredentials')
                                },
                                screenname:    {
                                    show: true,
                                    text: t('BadCredentials')
                                },
                                password: {
                                    show: true, 
                                    text: t('BadCredentials'),
                                },
                            });
                        }
                    })                    
                });
            });
        }
    }

    const showRecaptcha = props.captchaData && (props.captchaData.use || props.captchaData.useOnFail || props.captchaData.useOnSignup);
    const btnDisabled = showRecaptcha && !recaptchaResponse;

    
    const content = (
            <form action="#" className={"form"}>
                <div className="form-group__headline">
                    Telegram VIP
                    </div>
                <div className={"form-group"}>
                    <input className={"form-group__input"} type="email" onFocus={handleFocus} onBlur={handleFocus} onChange={handleChange} name="email" required />
                    <label className={"form-group__label " + (showLabel.email ? "form-group__label--move-top" : "")}>{t('Email')}</label>
                    <label className={"form-group__label-error " + (showError.email.show ? "form-group__label-error--show" : "")}>{showError.email.text}</label>
                </div>
                <div className={"form-group"}>
                    <input className={"form-group__input"} type="text" onFocus={handleFocus} onBlur={handleFocus} onChange={handleChange} name="screenname" required />
                    <label className={"form-group__label " + (showLabel.screenname ? "form-group__label--move-top" : "")}>{t('Username')}</label>
                    <label className={"form-group__label-error " + (showError.screenname.show ? "form-group__label-error--show" : "")}>{showError.screenname.text}</label>
                </div>
                <div className={"form-group"}>
                    <input className={"form-group__input"} type={showPassword ? "text" : "password"} onFocus={handleFocus} onBlur={handleFocus} onChange={handleChange} name="password" required/>
                    <label className={"form-group__label " + (showLabel.password ? "form-group__label--move-top" : "")}>{t('Password')}</label>
                    <label className={"form-group__label-error " + (showError.password.show ? "form-group__label-error--show" : "")}>{showError.password.text}</label>
                    <div onClick={togglePassword} className={"form-group__password-toggle"}><i className={"icon " +  (showPassword ? "icon--visibility-off" : "icon--visibility")}></i></div>
                </div>
                <div className={"form-group form-group--row form-group--mg form-group--left"}>
                     <i className={"icon icon--mr10 icon--sm icon--check"} />     
                     <span>
                         {t("SignupTos-1")}    
                        <Link to={isGerman() ? RouterHelper.Routes.DE.CONDITIONS : RouterHelper.Routes.EN.CONDITIONS}> {t('AbgLink')}</Link> 
                         { t("SignupTos-2")}    
                     </span>
                </div>
                <div className={"form-group form-group--block form-group--mg form-group--left"}>
                     {t('SignupDataProtection')}
                     <Link to={isGerman() ? RouterHelper.Routes.DE.DATA_PROTECTION : RouterHelper.Routes.EN.DATA_PROTECTION}> {t('DataProtectionLink')}</Link>.
                </div>
                {showRecaptcha && <div className={"form-group"}><div className="form-group__recaptcha"><ReCAPTCHA sitekey={props.captchaData.key} onChange={onCaptchaCompleted}/></div></div>}
                {!hasSubmit && <button className={"form-group__button " + (btnDisabled ? 'form-group__button--disabled' : '')} disabled={btnDisabled} type="submit" onClick={(e) => submit(e)}><i className={"icon icon--xs icon--user"}></i> {t('SugnUpFree')}</button>}
                {hasSubmit && <button className={"form-group__button"} type="submit" onClick={(e) => e.preventDefault()}><span className="spinner"></span></button>}
            </form>
        );

    return  <div className={"login-container"}>
                <div className={"login-container__message " + (showMessage.show ? "login-container__message--show " : "") + (showMessage.isError ? "login-container__message--error " : "")}>{showMessage.text}</div>
                <div>
                    <NavigationBar />
                    {content}
                </div>
                <div>
                    <div className={"signup__link"}>{t('AlreadySignedUp')} <span onClick={toLogin} className={"signup__link--blue"}>{t('LoginHere')}</span></div>
                    {PoweredBy}
                    <FooterMeta />
                </div>
            </div>;
}

export default SignUp;